import {
  createCookieConsent,
  CookieConsentConfig,
  useCookieConsent
} from '@teamnovu/kit-cookieconsent';

const consentConfig: CookieConsentConfig = {
  categories: {
    functional: {
      services: {
        analytics: { label: 'Google Analytics' }
      }
    }
  },
  language: {
    default: 'de',
    translations: {
      de: '/cookie/de.json',
      en: '/cookie/en.json',
      fr: '/cookie/fr.json'
    }
  }
};

export default defineNuxtPlugin(({ vueApp, $i18n }) => {
  consentConfig.language.default = ($i18n as any).locale.value;

  vueApp.use(createCookieConsent(consentConfig));

  if (process.client) {
    const cookieconsent = useCookieConsent()!;

    watch(($i18n as any).locale, (newLocale) => {
      cookieconsent.setLanguage(newLocale);
    });

    watch(
      cookieconsent.services,
      (services) => {
        const gtm = useGtm()

        gtm?.enable(services.analytics ?? false);
      },
      { immediate: true }
    );
  }
});
