<template>
  <div>
    <div
      class="rounded-full w-full h-full flex items-center mr-2 overflow-hidden relative"
      :class="
        theme === 'dark'
          ? 'border-[#93A1AF] border-[0.2px]'
          : 'border-white border-[0.2px] border-opacity-20'
      "
    >
      <label
        class="sr-only"
        for="language-switch"
        >Language</label
      >
      <select
        :id="id"
        :class="theme === 'dark' ? 'text-black' : 'text-white'"
        @change="onLanguageSelect"
      >
        <option>{{ activeLanguage?.toUpperCase() }}</option>
        <template v-for="(translation, i) in pageTranslationsUrls">
          <option
            v-if="translation.name !== activeLanguage"
            :value="i"
          >
            {{ translation.name.toUpperCase() }}
          </option>
        </template>
      </select>
      <div
        id="arrow"
        class="absolute right-4 lg:right-4 top-1/2 -translate-y-1/2 pointer-events-none"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6.5L8 10.5L12 6.5"
            :stroke="theme === 'dark' ? 'black' : 'white'"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { usePageStore } from '~/stores/usePageStore';
import { useSettingsStore } from '~/stores/useSettingsStore';
import i18nConfig from '~/i18ncustom.config';

interface LanguageSwitchProps {
  theme?: 'dark' | 'light';
  menuDirection?: 'up' | 'down';
  id?: string | 'language-switch';
}

const { theme } = defineProps<LanguageSwitchProps>();

const i18n = useI18n();
const activeLanguage = computed(() => i18n.localeProperties.value.name);
const pageStore = usePageStore();
const { page } = storeToRefs(pageStore);
const localePath = useLocalePath();
const router = useRouter();
const settingsStore = useSettingsStore();

const onLanguageSelect = async (event: Event) => {
  const { target } = event;
  const selectedLocale = (target as HTMLSelectElement).value;
  const t = pageTranslationsUrls.value[selectedLocale];
  i18n.setLocaleCookie(t.code);

  try {
    await settingsStore.loadSettings(i18n.locale.value);
  } catch (error) {
    console.error(error);
  }
  // await i18n.setLocale(t.code);

  await router.push(localePath(t.slug, t.code));
};

const pageTranslationsUrls = computed(() => {
  if (!page.value.translations) {
    return [];
  }

  return i18nConfig.locales.map((l) => {
    const { url, isHome } = page.value?.translations[l.code] || {};
    // if no slug is set, then redirect to homepage of same language
    const slug = url?.fullPath ? `${isHome ? '' : '/'}${url?.fullPath}` : '/';
    return {
      code: l.code,
      slug,
      name: l.name
    };
  });
});
</script>

<style scoped lang="postcss">
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  @apply bg-transparent absolute top-0 left-0 w-full h-full m-0 cursor-pointer pl-4;
  @apply text-[10px] uppercase paragraph-1;
  letter-spacing: 1.3px;
  font-weight: 461;
}

option {
  @apply paragraph-1;
}

select:disabled {
  color: gray !important;
}

select::-ms-expand {
  display: none;
}

.theme-dark {
  .active-language {
    @apply flex items-center cursor-pointer uppercase tracking-wider relative h-fit;

    span {
      @apply text-black mr-1 text-sm;
    }

    .arrow {
      svg {
        path {
          stroke: black;
        }
      }
    }
  }
}
.theme-light {
  svg {
    path {
      stroke: white;
    }
  }
}
</style>
