import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import siteConfig_Eeyr9ytLpT from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/plugins/siteConfig.mjs";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/vercel/path0/.nuxt/gsapPlugin.mjs";
import plugin_EA4M3qq0w7 from "/vercel/path0/node_modules/@nuxtjs/sanity/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import lenis_client_2wiro2fsNq from "/vercel/path0/plugins/lenis.client.ts";
import vue_turnstile_client_JORseVri29 from "/vercel/path0/plugins/vue-turnstile.client.ts";
import cookieconsent_hBUClLyRD3 from "/vercel/path0/plugins/cookieconsent.ts";
import loadSettingsOnLangSwitch_nwRHu1tMr0 from "/vercel/path0/plugins/loadSettingsOnLangSwitch.ts";
import locomotive_client_ykwvJziAyw from "/vercel/path0/plugins/locomotive.client.ts";
import markdown_nljPFoZ1ix from "/vercel/path0/plugins/markdown.ts";
import vue_masonry_wall_13R6vu58Yn from "/vercel/path0/plugins/vue-masonry-wall.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  siteConfig_Eeyr9ytLpT,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  gsapPlugin_HlwIIyl85L,
  plugin_EA4M3qq0w7,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_UciE0i6zes,
  lenis_client_2wiro2fsNq,
  vue_turnstile_client_JORseVri29,
  cookieconsent_hBUClLyRD3,
  loadSettingsOnLangSwitch_nwRHu1tMr0,
  locomotive_client_ykwvJziAyw,
  markdown_nljPFoZ1ix,
  vue_masonry_wall_13R6vu58Yn
]