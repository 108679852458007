// Create a composable that will be used to get the site settings
// A method extract the
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';
import { AppLink, Menu, NavigationDocument } from '~/types';

export const useSiteSettings = () => {
  const store = useSettingsStore();
  const { staticText, sitemap, socialMedia, legal, navigations } = storeToRefs(store);
  const locale = useNuxtApp().$i18n.locale;

  const $text = (key: string): string | undefined => {
    return staticText.value.find(({ entry }) => entry.key === key)?.entry.value;
  };

  const $fullpath = (_ref: string): string => {
    if (!_ref) {
      return '';
    }
    const page = sitemap.value.find((page) => page._id === _ref);
    // patch: if baseLocale, then the path should be without the locale
    const lang = locale.value === 'de' ? '/' : `/${locale.value}/`;

    return `${lang}${page?.url.fullPath}` || '';
  };

  const getMenuById = (menuId: string): NavigationDocument | undefined => {
    if (!menuId) {
      return;
    }

    return navigations.value.find((menu) => menu.menuId === menuId);
  };

  const buildNavigationTree = (menu: NavigationDocument | undefined) => {
    if (!menu) return;

    return menu.navigation.map((n: Menu) => createMenuItem(n)).filter((n) => n);
  };

  const createMenuItem = (menuItem: Menu): AppLink => {
    const isJumplink = !!menuItem?.isJumplink;
    const isExternal = !!menuItem?.isExternal;

    if (isExternal) {
      return createExternalLink(menuItem);
    }

    if (isJumplink) {
      return createJumplink(menuItem);
    }
    const fullPath = $fullpath(menuItem.pageRef?._ref) || '';

    const to = menuItem?.pageRef ? fullPath : '';

    return {
      to,
      label: menuItem.title,
      hasLink: !!menuItem.pageRef,
      isDropdown: menuItem.children?.length > 0,
      children: menuItem.children?.length > 0 && menuItem.children.map((c) => createMenuItem(c))
    };
  };

  const createJumplink = (menuItem: Menu): AppLink => {
    return {
      label: menuItem.title,
      isJumplink: !!menuItem?.isJumplink,
      to: $fullpath(menuItem.jumplink.internalLink?._ref),
      sectionId: menuItem.jumplink.sectionId
    };
  };

  const createExternalLink = (menuItem: Menu): AppLink => {
    return {
      label: menuItem.label,
      isJumplink: false,
      isExternal: true,
      to: menuItem.externalLink || '',
      sectionId: ''
    };
  };

  const backgroundColorClass = (page: any) => {
    if (page.detailPageSettings?.hasBackground && page.detailPageSettings?.pageBackgroundColor) {
      if (page.detailPageSettings.pageBackgroundColor.label === 'Gold') {
        return 'bg-secondary';
      }
      if (page.detailPageSettings.pageBackgroundColor.label === 'Rose') {
        return 'bg-rose';
      }
      if (page.detailPageSettings.pageBackgroundColor.label === 'Green') {
        return 'bg-mint';
      }
    }

    return 'bg-white';
  };

  const convertDateToEuropeanFormat = (date: string) => {
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
  };

  return {
    $text,
    $fullpath,
    getMenuById,
    buildNavigationTree,
    backgroundColorClass,
    convertDateToEuropeanFormat
  };
};
