import locale_locales_locales_de from "../locales/de.json" assert { type: "json" };
import locale_locales_locales_fr from "../locales/fr.json" assert { type: "json" };
import locale_locales_locales_en from "../locales/en.json" assert { type: "json" };
export const localeCodes = ["de","fr","en"]

export const localeMessages = {
  "de": [{ key: "../locales/de.json", load: () => Promise.resolve(locale_locales_locales_de) }],
  "fr": [{ key: "../locales/fr.json", load: () => Promise.resolve(locale_locales_locales_fr) }],
  "en": [{ key: "../locales/en.json", load: () => Promise.resolve(locale_locales_locales_en) }],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.bundle = Object({"compositionOnly":true})
  nuxtI18nOptions.compilation = Object({"jit":true,"strictMessage":true,"escapeHtml":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  nuxtI18nOptions.vueI18n = Object({})
  nuxtI18nOptions.vueI18n.messages ??= {}
  nuxtI18nOptions.locales = [Object({"code":"de","iso":"de-DE","name":"Deutsch","files":["locales/de.json"],"hashes":["d44fe104"],"types":["static"]}),Object({"code":"fr","iso":"fr","name":"Français","files":["locales/fr.json"],"hashes":["59206886"],"types":["static"]}),Object({"code":"en","iso":"en","name":"English","files":["locales/en.json"],"hashes":["ebecf2e8"],"types":["static"]})]
  nuxtI18nOptions.defaultLocale = "de"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = false
  nuxtI18nOptions.langDir = "./locales"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_app","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = "production"
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  nuxtI18nOptions.parallelPlugin = false
  nuxtI18nOptions.i18nModules = []
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),bundle: Object({"compositionOnly":true}),compilation: Object({"jit":true,"strictMessage":true,"escapeHtml":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false,parallelPlugin: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"de","iso":"de-DE","name":"Deutsch","files":["locales/de.json"],"hashes":["d44fe104"],"types":["static"]}),Object({"code":"fr","iso":"fr","name":"Français","files":["locales/fr.json"],"hashes":["59206886"],"types":["static"]}),Object({"code":"en","iso":"en","name":"English","files":["locales/en.json"],"hashes":["ebecf2e8"],"types":["static"]})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const parallelPlugin = false
