<template>
  <div
    class="address-block"
    :class="theme === 'light' ? 'light' : 'dark'"
  >
    <address class="address richtext">
      <SanityContent :blocks="siteParts?.contactInfo?.contactText" />
    </address>
    <NuxtLink
      target="_BLANK"
      class="paragraph-3 text-white not-italic inline-flex items-center no-underline arrow-wrapper"
      :to="siteParts?.contactInfo?.googleMapsLink"
    >
      <ArrowLink
        class="arrowLink text-white"
        :text="$text('google-directions-button') || 'Google Maps'"
        :tag="'span'"
      />
    </NuxtLink>
  </div>
</template>
<script setup>
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';

const { theme } = defineProps({
  theme: {
    type: String,
    default: 'dark'
  }
});

const { siteParts } = storeToRefs(useSettingsStore());
const { $text } = useSiteSettings();
</script>

<style lang="postcss">
.light {
  .arrow-wrapper {
    svg {
      height: 100%;
      path {
        stroke: white;
      }
    }
  }

  .link-after {
    &:hover {
      @apply text-white;
    }
  }
}
</style>
