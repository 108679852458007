import { defineStore } from 'pinia';
import { SanityPage } from '~/types';

export const usePageStore = defineStore('page', () => {
  const page = reactive<Partial<SanityPage>>({
    translations: {
      en: {},
      de: {},
      fr: {}
    }
  });

  const setPageTranslations = (currentPage: Partial<SanityPage>) => {
    page.translations = { ...currentPage.translations };
  };

  return {
    page,
    setPageTranslations
  };
});
