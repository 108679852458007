<template>
  <NavigationTree
    v-if="navigationTree"
    :children="navigationTree"
  />
</template>

<script setup lang="ts">
const { menuId } = defineProps<{
  menuId: any;
}>();
const { buildNavigationTree, getMenuById } = useSiteSettings();
const menu = computed(() => getMenuById(menuId));
const navigationTree = computed(() => buildNavigationTree(menu.value));
</script>
