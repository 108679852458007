type Breakpoint = string | null;
import { ref, onMounted, onBeforeUnmount } from "vue";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "./../tailwind.config";

export function useActiveBreakpoint() {
  const currentBreakpoint = ref<Breakpoint>(null);

  function getActiveBreakpoint() {
    const breakpoints = resolveConfig(tailwindConfig)?.theme?.screens;

    for (const breakpoint in breakpoints) {
      const minWidth = breakpoints[breakpoint];
      const mediaQuery =
        breakpoint === "sm"
          ? `(min-width: 0px), (max-width: ${minWidth})`
          : `(min-width: ${minWidth})`;

      if (window.matchMedia(mediaQuery).matches) {
        currentBreakpoint.value = breakpoint;
      }
    }
  }

  onMounted(() => {
    getActiveBreakpoint();
    window.addEventListener("resize", getActiveBreakpoint);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", getActiveBreakpoint);
  });

  return {
    currentBreakpoint,
  };
}
