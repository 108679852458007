import { storeToRefs } from 'pinia';
import { SiteSettings } from '~/types';
import { useSettingsStore } from '~/stores/useSettingsStore';

// check if there is a settings cookie
// if not, load settings data from sanity.
export default defineNuxtRouteMiddleware(async (to, from) => {
  // We need to re-calculate the new page
  // TODO find solution for that:
  // setTimeout(function () {
  //   useNuxtApp().locomotiveScroll.update();
  // }, 500);
  // ----------------------------------------------
  // console.log('useNuxtApp', );
  // let loco = useNuxtApp.locomotiveScroll;
  // loco.update();
  // const store = useSettingsStore();
  // // const { settings } = storeToRefs(store);
  // // load settings data from cookie to pinia store
  // // const cachedSettings: Ref<SiteSettings> = useCookie('settings');
  // // if (cachedSettings.value) {
  // //   settings.value.legal = { ...cachedSettings.value.legal };
  // //   settings.value.socialMedia =
  // //     cachedSettings.value.socialMedia && cachedSettings.value.socialMedia.length > 0
  // //       ? [...cachedSettings.value.socialMedia]
  // //       : [];
  // //   settings.value.staticText = [...cachedSettings.value.staticText];
  // //   settings.value.navigations =
  // //     cachedSettings.value.navigations && cachedSettings.value.navigations.length > 0
  // //       ? [...cachedSettings.value.navigations]
  // //       : [];
  // //   settings.value.sitemap =
  // //     cachedSettings.value.sitemap && cachedSettings.value.sitemap.length > 0
  // //       ? [...cachedSettings.value.sitemap]
  // //       : [];
  // //   return;
  // // }
  // // trigger store action to load settings from sanity
  // const { loadSettings } = store;
  // try {
  //   await loadSettings();
  // } catch (error) {
  //   console.error(error);
  // }
});
