<template>
  <div ref="gsapAnimationOnScroll">
    <slot />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, onUpdated, nextTick } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// gsap plugins must be registered
gsap.registerPlugin(ScrollTrigger);

const gsapAnimationOnScroll = ref(null);
let timeline = null;

const onResize = () => {
  if (timeline) {
    timeline.kill();
  }
  nextTick(() => {
    animateOnScroll();
  });
};

const animateOnScroll = () => {
  const child = gsapAnimationOnScroll.value;
  if (!child) {
    return;
  }

  timeline = gsap.timeline({
    scrollTrigger: {
      trigger: child,
      start: 'top bottom',
      end: 'bottom center',
      scrub: 0.2,
      invalidateOnRefresh: true
    }
  });

  try {
    timeline.fromTo(child, { translateY: '-100px' }, { translateY: '0px' }, '-=0.1');
  } catch (e) {
    console.log(e);
  }
};

onMounted(() => {
  nextTick(() => {
    animateOnScroll();
    window.addEventListener('resize', onResize);
    setTimeout(() => {
      if (timeline) {
        timeline.scrollTrigger.refresh();
      }
    }, 600);
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
  if (timeline) {
    timeline.kill();
  }
});

onUpdated(() => {
  onResize();
});
</script>
