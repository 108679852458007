import { defineStore } from 'pinia';
import { SiteSettings } from '~/types';
import getSiteSettingsQuery from '~/queries/getSiteSettingsQuery';

export const useSettingsStore = defineStore('settings', () => {
  const settings = reactive<SiteSettings>({
    legal: null,
    socialMedia: [],
    staticText: [],
    sitemap: [],
    navigations: [],
    siteParts: null
  });

  const siteParts = computed(() => settings.siteParts);
  const legal = computed(() => settings.legal);
  const socialMedia = computed(() => settings.socialMedia);
  const staticText = computed(() => settings.staticText);
  const sitemap = computed(() => settings.sitemap);
  const navigations = computed(() => settings.navigations);
  // const cookieSeason = useCookie('season');
  // We load current season from cookie to avoid inconsistency
  // if user refresh the other season page (summer or winter) that is not set as homepage
  // if (cookieSeason.value) {
  //   season.value = cookieSeason.value;
  // } else {
  //   // we have to toggle between summer and winter
  //   // so if we are in summer, we could be interested in the opposite season
  //   season.value = siteParts.value?.header.season;
  // }

  const loadSettings = async (locale?: string) => {
    const i18n = useNuxtApp().$i18n; // hooks triggers an error
    const query = getSiteSettingsQuery(locale || i18n.locale.value);
    const sanity = useNuxtApp().$sanity; // hooks triggers an error
    const response = await sanity.fetch<SiteSettings>(query);

    if (response) {
      settings.siteParts = response.siteParts;
      settings.legal = response.legal;
      settings.socialMedia = response.socialMedia;
      settings.staticText = response.staticText;
      settings.sitemap = response.sitemap;
      settings.navigations = response.navigations;
      settings.siteParts = response.siteParts;

      // save settings to localstorage
      // const cachedSettings = useCookie('settings');
      // cachedSettings.value = JSON.stringify(response);
    }
  };

  return {
    siteParts,
    settings,
    legal,
    socialMedia,
    staticText,
    sitemap,
    navigations,
    loadSettings
  };
});
