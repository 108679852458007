<template>
  <header class="header fade-in h-headerHeight z-0">
    <div
      class="container flex items-center justify-between lg:justify-normal"
      :class="uiStore.ui.isOverlayActive ? 'overlay-active' : ''"
    >
      <div class="hidden lg:inline-block lg:w-[40%]">
        <NavigationTopnav
          class="top-header-nav fade-in-top-slow"
          menuId="top-header"
        />
      </div>

      <div
        class="mobile-menu"
        @click="uiStore.toggleOverlay()"
      >
        <SVGLoader name="mobile-menu" />
      </div>

      <NuxtLink
        :to="localePath('/')"
        class="flex justify-center no-underline fade-in-top-slow w-[20%]"
        aria-label="Home Page"
      >
        <SVGLoader name="logoMinimal" />
      </NuxtLink>

      <div class="w-auto lg:w-[40%] flex justify-end items-center fade-in-top-slow">
        <SeasonSwitch
          v-if="page?.isHome || isSeasonHomePage"
          class="hidden lg:flex"
        />
        <Button
          v-if="siteParts"
          class="!hidden w-[120px] lg:!flex !mx-0 !ml-2 h-[42px] !py-2 !px-0 !min-w-[60px] !max-w-[120px]"
          :to="bookingButtonUrl"
          :isExternal="siteParts.general?.bookingEngineLink?.isExternal || false"
          :target="siteParts.general?.bookingEngineLink?.isExternal ? '_blank' : ''"
          variant="primary"
          variantType="normal"
          >{{ siteParts.general?.bookingEngineLink?.label }}
        </Button>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';
import { useUiStore } from '~/stores/useUiStore';

const { page } = defineProps({
  page: Object
});

const { fullPath } = useRoute();
const { locale } = useI18n();
const uiStore = useUiStore();
const settingsStore = useSettingsStore();
const { siteParts } = storeToRefs(settingsStore);
const localePath = useLocalePath();
const { $fullpath, $text } = useSiteSettings();
const winterPageUrl = $fullpath(siteParts.value?.header.winterPageLink._ref || '');
const summerPageUrl = $fullpath(siteParts.value?.header.summerPageLink._ref || '');

const isSeasonHomePage = computed(
  () => fullPath === `${summerPageUrl}` || fullPath === `${winterPageUrl}`
);

const bookingButtonUrl = computed(() => {
  return siteParts.value?.general?.bookingEngineLink?.isExternal
    ? siteParts.value?.general?.bookingEngineLink?.externalLink
    : $fullpath(siteParts.value?.general?.bookingEngineLink?.link._ref || '');
});

function toggleFlyOutNav() {
  uiStore.toggleFlyOutNav();
}
</script>

<style lang="postcss">
.header {
  @apply relative w-full;
  @apply flex text-black items-center justify-between;

  .top-header-nav {
    @apply hidden lg:flex pl-0;
    li {
      @apply list-none mb-0;
      /* opacity: 0;
      transform: translateY(-20px);
      animation: fadeInUp 0.5s ease forwards; */
    }
  }
  /* .top-header-nav li:nth-child(1) {
    animation-delay: 0s;
  }
  .top-header-nav li:nth-child(2) {
    animation-delay: 0.1s;
  }
  .top-header-nav li:nth-child(3) {
    animation-delay: 0.2s;
  }
  .top-header-nav li:nth-child(4) {
    animation-delay: 0.3s;
  }
  .top-header-nav li:nth-child(5) {
    animation-delay: 0.4s;
  } */

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  a {
    @apply !no-underline uppercase mx-6;
    font-size: fluid(10, 12, 640, 1792);
    &:first-child {
      @apply ml-0;
    }
  }

  div {
    @apply !no-underline uppercase text-[10px];
  }

  .mobile-menu {
    @apply lg:hidden cursor-pointer;
  }
}
</style>
