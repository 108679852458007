import { defineStore } from 'pinia';
import { SiteUi } from '~/types';

export const useUiStore = defineStore('ui', () => {
  const ui = reactive<SiteUi>({
    isOverlayActive: false,
    isFlyOutActive: false,
    isWebcamOverlayActive: false,
    videoMainIsPlaying: false
  });

  const toggleOverlay = () => {
    ui.isOverlayActive = !ui.isOverlayActive;
  };

  const toggleFullScreenVideo = () => {
    ui.videoMainIsPlaying = !ui.videoMainIsPlaying;
  };

  const closeOverlay = () => {
    ui.isOverlayActive = false;
  };

  const toggleWebcamOverlay = () => {
    ui.isWebcamOverlayActive = !ui.isWebcamOverlayActive;
  };

  const closeWebcamOverlay = () => {
    ui.isWebcamOverlayActive = false;
  };

  const toggleFlyOutNav = () => {
    ui.isFlyOutActive = !ui.isFlyOutActive;
  };

  const closeFlyOutNav = () => {
    ui.isFlyOutActive = false;
  };

  return {
    ui,
    toggleWebcamOverlay,
    closeWebcamOverlay,
    toggleOverlay,
    closeOverlay,
    toggleFlyOutNav,
    closeFlyOutNav,
    toggleFullScreenVideo
  };
});
