import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as media_45corner9241vhduM9Meta } from "/vercel/path0/pages/media-corner.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug___fr",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: media_45corner9241vhduM9Meta?.name ?? "media-corner___de",
    path: media_45corner9241vhduM9Meta?.path ?? "/media-corner",
    meta: media_45corner9241vhduM9Meta || {},
    alias: media_45corner9241vhduM9Meta?.alias || [],
    redirect: media_45corner9241vhduM9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/media-corner.vue").then(m => m.default || m)
  },
  {
    name: media_45corner9241vhduM9Meta?.name ?? "media-corner___fr",
    path: media_45corner9241vhduM9Meta?.path ?? "/fr/media-corner",
    meta: media_45corner9241vhduM9Meta || {},
    alias: media_45corner9241vhduM9Meta?.alias || [],
    redirect: media_45corner9241vhduM9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/media-corner.vue").then(m => m.default || m)
  },
  {
    name: media_45corner9241vhduM9Meta?.name ?? "media-corner___en",
    path: media_45corner9241vhduM9Meta?.path ?? "/en/media-corner",
    meta: media_45corner9241vhduM9Meta || {},
    alias: media_45corner9241vhduM9Meta?.alias || [],
    redirect: media_45corner9241vhduM9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/media-corner.vue").then(m => m.default || m)
  }
]