import { useSettingsStore } from '~/stores/useSettingsStore';
import { useEventsStore } from '~/stores/useEventsStore';

export default defineNuxtPlugin((nuxtApp) => {
  // called right after a new locale has been set
  nuxtApp.hook('i18n:localeSwitched', async ({ oldLocale, newLocale }) => {
    const settingsStore = useSettingsStore();
    const eventsStore = useEventsStore();

    try {
      await settingsStore.loadSettings(newLocale);
      await eventsStore.fetchEventsFromApi(newLocale);
    } catch (error) {
      console.error(error);
    }
  });
});
