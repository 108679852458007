import Lenis from '@studio-freight/lenis';

export default defineNuxtPlugin((nuxtApp) => {
  //   let lenis = new Lenis({
  //     duration: 1.2,
  //     easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  //     orientation: 'vertical', // vertical, horizontal
  //     gestureOrientation: 'vertical', // vertical, horizontal, both
  //     smoothWheel: true,
  //     wheelMultiplier: 1,
  //     smoothTouch: false,
  //     touchMultiplier: 2,
  //     infinite: false
  //   });
  //   // Wrap the .resize() function
  //   function resizeLenis() {
  //     alert('resize lenis');
  //     lenis.destroy();
  //     lenis = new Lenis({
  //       duration: 1.2,
  //       easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  //       orientation: 'vertical', // vertical, horizontal
  //       gestureOrientation: 'vertical', // vertical, horizontal, both
  //       smoothWheel: true,
  //       wheelMultiplier: 1,
  //       smoothTouch: false,
  //       touchMultiplier: 2,
  //       infinite: false
  //     });
  //   }
  //   const raf = (time: number) => {
  //     lenis.raf(time);
  //     requestAnimationFrame(raf);
  //   };
  //   requestAnimationFrame(raf);
  //   // Make Lenis instance available globally
  //   nuxtApp.provide('lenis', lenis);
  //   nuxtApp.provide('resizeLenis', resizeLenis);
});
