<template>
  <ul>
    <li
      v-for="(node, i) in children"
      :key="i"
    >
      <!-- <template v-if="!!node.isJumplink && node.isJumplink">
        <a :href="`${activeLocale}/${node.to}#${node.sectionId}`">
          {{ node.label }}
        </a>
      </template> -->
      <template v-if="!!node.isExternal && node.isExternal">
        <a
          :href="node.to"
          target="_blank"
        >
          {{ node.label }}
        </a>
      </template>
      <template v-else-if="!!node.isDropdown && node.isDropdown">
        <!-- This might be something like toggles or arrows -->
        <NuxtLink
          v-if="node.hasLink"
          :to="`${node.to}`"
        >
          {{ node.label }}
        </NuxtLink>
        <a
          v-else
          href="#"
        >
          {{ node.label }}
        </a>
        <NavigationTree :children="node.children" />
      </template>
      <template v-else>
        <NuxtLink :to="`${node.to}`">
          {{ node.label }}
        </NuxtLink>
      </template>
    </li>
  </ul>
</template>

<script setup lang="ts">
const { children } = defineProps<{
  children: any;
}>();

const { locale } = useI18n();
const activeLocale = computed(() => (locale.value === 'de' ? '' : `/${locale.value}`));
</script>
