<template>
  <div class="toggle-switch">
    <label>
      <input
        type="checkbox"
        :checked="season === 'summer'"
        @click.stop="toggleSeason"
      />
      <span class="slider"></span>
      <p
        class="text-[10px] text-toggle transition-all duration-300 uppercase left-[5px]"
        :class="season === 'summer' ? '' : 'opacity-0'"
      >
        {{ $text('summer') }}
      </p>
      <p
        class="text-[10px] text-toggle text-winter transition-all duration-300 uppercase"
        :class="season === 'winter' ? '' : 'opacity-0'"
      >
        {{ $text('winter') }}
      </p>
    </label>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';

const router = useRouter();
const { $fullpath, $text } = useSiteSettings();
const settingsStore = useSettingsStore();
const { siteParts } = storeToRefs(settingsStore);
const cookieSeason = useCookie('season');
const season = computed(() => cookieSeason.value || siteParts.value?.header.season || 'no-season');

const toggleSeason = () => {
  let url: string = '';
  if (season.value === 'summer') {
    url = $fullpath(siteParts.value?.header.winterPageLink._ref || '/');

    // season.value = 'winter';
    cookieSeason.value = 'winter';
  } else if (season.value === 'winter') {
    url = $fullpath(siteParts.value?.header.summerPageLink._ref || '/');
    // season.value = 'summer';
    cookieSeason.value = 'summer';
  }

  setTimeout(async () => {
    await router.push(url === '' ? '' : url);
  }, 200);
};
</script>

<style lang="postcss" scoped>
.toggle-switch {
  --height-btn: 42px;
  --width-btn: 110px;
  --height-icon: 24px;
  --padding-icon: 6px;
  height: var(--height-btn);
  @apply ml-3;
  position: relative;
  width: var(--width-btn);
}

.text-toggle {
  @apply h-fit px-2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.text-winter {
  @apply right-[12px];
}

label {
  @apply border-[#93A1AF] border-[1px] border-opacity-20;
  position: absolute;
  width: 100%;
  height: var(--height-btn);
  border-radius: 50px;
  cursor: pointer;
  font-size: 10px;
}

input {
  position: absolute;
  display: none;
}

.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  transition: 0.3s;
}

/* input:checked ~ .slider {
  background-color: green;
} */

.slider::before {
  content: url('../assets/svg/winter.svg');
  position: absolute;
  top: calc((var(--height-btn) - var(--height-icon)) / 2);
  left: var(--padding-icon);
  width: var(--height-icon);
  height: var(--height-icon);
  border-radius: 50%;
  transition: 0.3s;
}

input:checked ~ .slider::before {
  content: url('../assets/svg/sun.svg');
  transform: translateX(calc(var(--width-btn) - var(--height-icon) - 3 * var(--padding-icon)));
  box-shadow: none;
}
</style>
