// formkit.config.js
import { generateClasses } from '@formkit/themes';
import { genesisIcons } from '@formkit/icons';
import myTheme from './form-theme'; // change to your theme's path
import { defineFormKitConfig } from '@formkit/vue';

export default defineFormKitConfig({
  icons: {
    ...genesisIcons
  },
  config: {
    classes: generateClasses(myTheme)
  }
});
