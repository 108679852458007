import { defineStore } from 'pinia';
import { CleanDate, EventsApi, Item } from '~/types';

export const useEventsStore = defineStore('events', () => {
  const selectedEvent = ref<Item | null>(null);
  const eventApi = ref<EventsApi>();
  const storePending = ref<boolean>(false);
  const currentMonth = new Date().getMonth() + 1;
  const waldhausSilsResortFilter = `channels-waldhaus-sils-ch`;
  const locale = useNuxtApp().$i18n.locale;
  const today = new Date();

  const fetchEventsFromApi = async (newLocale?: string) => {
    let apiUrl = '';
    if (newLocale) {
      apiUrl =
        newLocale === 'de'
          ? 'https://developers.estm.ch/hotel-waldhaus-sils/events'
          : 'https://developers.estm.ch/en/hotel-waldhaus-sils/events';
    } else {
      apiUrl =
        locale.value === 'de'
          ? 'https://developers.estm.ch/hotel-waldhaus-sils/events'
          : 'https://developers.estm.ch/en/hotel-waldhaus-sils/events';
    }
    try {
      const { data, pending } = await useFetch<EventsApi>(apiUrl);
      storePending.value = pending.value;

      if (data.value) {
        eventApi.value = data.value;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // computed properties
  const filterKeys = computed(() => Object.keys(eventApi.value?.content[0].data.facets || {}));
  // for each event in data.value, we have to spread the cleanDates array
  // and create a new object for each date copying the event data to being able
  // to manage slugs and filtering as intended
  const allEvents = computed(() => {
    const events = eventApi.value?.content[0]?.data.items.map((item: Item) => {
      const { cleanDates, ...event } = item;
      if (!cleanDates) return;

      const events = cleanDates.map((date: CleanDate) => {
        return {
          ...event,
          cleanDate: date
        };
      });

      return events;
    });

    return events?.flat().sort((a: Item, b: Item) => {
      // Compare by startDate first
      if (a.cleanDate?.startDate < b.cleanDate?.startDate) return -1;
      if (a.cleanDate?.startDate > b.cleanDate?.startDate) return 1;

      // If startDate is the same, compare by startTime
      if (a.cleanDate?.startTime < b.cleanDate?.startTime) return -1;
      if (a.cleanDate?.startTime > b.cleanDate?.startTime) return 1;

      return 0; // If both dates and times are the same
    });
  });

  const defaultEvents = computed(
    () =>
      allEvents.value &&
      allEvents.value?.filter((event) => {
        if (!event) return;

        // discard all dates in the past
        const eventDate = new Date(event.cleanDate.startDate);
        // Set the time to midnight for both dates to only compare the date part
        today.setHours(0, 0, 0, 0);
        eventDate.setHours(0, 0, 0, 0);

        const isInPast = eventDate < today;
        // as soon as we get one match, we return the whole object with all the dates
        const isInCurrentMonth =
          new Date(event.cleanDate.startDate).getMonth() + 1 === currentMonth;

        const isInWaldhausSilsCanton =
          event.listFacetIdAttribute.includes(waldhausSilsResortFilter);

        return isInCurrentMonth && isInWaldhausSilsCanton && !isInPast;
      })
  );

  const categoryFilters: ComputedRef<{ label: string; value: string }[]> = computed(() => {
    const filters =
      eventApi.value?.content[0] && filterKeys.value.length > 0
        ? Object.values(
            eventApi.value?.content[0]?.data.facets[filterKeys.value[1]]?.facetAttributes
          )
        : [];

    // we set a default value to reset the filters
    filters.unshift({
      title: 'Kategorie',
      facedId: '',
      facetKeyTypo3: '',
      isActive: true
    });

    return filters.map((item) => ({ label: item.title, value: item.facetKeyTypo3 }));
  });

  const resortFilters: ComputedRef<{ label: string; value: string }[]> = computed(() => {
    const filters =
      eventApi.value?.content[0] && filterKeys.value.length > 0
        ? Object.values(
            eventApi.value?.content[0]?.data.facets[filterKeys.value[0]]?.facetAttributes
          )
        : [];
    // we set a default value to reset the filters
    filters.unshift({
      title: 'All Resorts',
      facedId: '',
      facetKeyTypo3: 'string',
      isActive: true
    });

    return filters.map((item) => ({ label: item.title, value: item.facedId }));
  });

  const monthsFilter = computed(() => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const nextYear = currentYear + 1;
    const months = [];
    for (let i = currentMonth; i < 12; i++) {
      months.push({ label: `${i + 1}/${currentYear}`, value: i + 1 });
    }
    for (let i = 0; i < currentMonth; i++) {
      months.push({ label: `${i + 1}/${nextYear}`, value: i + 1 });
    }
    return months;
  });

  const getEventBySlug = (slug: string, options?: { startDate: string; startTime: string }) => {
    // slug should be parsed from the cleanDate.startDate && cleanDate.startTime properties
    console.log('slugs', slug);
    console.log('options', options);
    return allEvents.value?.find(
      (event) =>
        event?.slug.includes(slug) &&
        event.cleanDate.startDate === options?.startDate &&
        event.cleanDate.startTime === options?.startTime
    );
  };

  return {
    defaultEvents,
    storePending,
    allEvents,
    categoryFilters,
    resortFilters,
    monthsFilter,
    selectedEvent,
    fetchEventsFromApi,
    getEventBySlug
  };
});
