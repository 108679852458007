export default function getImageDataFromRef(ref: string) {
  return groq`*[_id=="${ref}"][0]{
    "asset":upload.asset->{
      ...
    },
    "crop":upload.crop,
    "hotspot":upload.hotspot,
    title
  }`;
}
