export default {
  defaultLocale: "de",
  langDir: "./locales",
  locales: [
    {
      code: "de",
      iso: "de-DE",
      file: "de.json",
      name: "Deutsch",
    },
    {
      code: "fr",
      iso: "fr",
      file: "fr.json",
      name: "Français",
    },
    {
      code: "en",
      iso: "en",
      file: "en.json",
      name: "English",
    },
  ],
  baseUrl: process.env.NODE_ENV,
  strategy: "prefix_except_default",
  // detectBrowserLanguage: false
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: "i18n_app",
    redirectOn: 'root'
  },
};
