<template>
  <ul>
    <li
      v-for="(node, i) in children"
      :key="i"
    >
      <template v-if="!!node.isExternal && node.isExternal">
        <div class="relative overflow-hidden group w-fit ml-auto">
          <a
            class="group-hover:-translate-y-[101%] transition-all duration-700 inline-block ease-in-dino"
            :href="node.to"
            target="_blank"
          >
            {{ node.label }}
          </a>
          <a
            class="absolute left-0 !ml-0 group-hover:translate-y-[0] translate-y-[101%] top-0 transition-all duration-700 ease-in-dino"
            :href="node.to"
            target="_blank"
          >
            {{ node.label }}
          </a>
        </div>
      </template>
      <template v-else-if="!!node.isDropdown && node.isDropdown">
        <!-- This might be something like toggles or arrows -->
        <NuxtLink
          v-if="node.hasLink"
          :to="`${node.to}`"
        >
          {{ node.label }}
        </NuxtLink>
        <a
          v-else
          href="#"
        >
          {{ node.label }}
        </a>
        <NavigationTree :children="node.children" />
      </template>
      <template v-else>
        <div class="relative overflow-hidden group w-fit ml-auto">
          <NuxtLink
            class="group-hover:-translate-y-[101%] transition-all duration-700 inline-block ease-in-dino"
            :to="`${node.to}`"
          >
            {{ node.label }}
          </NuxtLink>
          <NuxtLink
            class="absolute left-0 !ml-0 group-hover:translate-y-[0] translate-y-[101%] top-0 transition-all duration-700 ease-in-dino"
            :to="`${node.to}`"
          >
            {{ node.label }}
          </NuxtLink>
        </div>
      </template>
    </li>
  </ul>
</template>

<script setup lang="ts">
const { children } = defineProps<{
  children: any;
}>();

const { locale } = useI18n();
const activeLocale = computed(() => (locale.value === 'de' ? '' : `/${locale.value}`));
</script>
