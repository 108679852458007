<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useSettingsStore } from '~/stores/useSettingsStore';
// check if locale has changed and if so, reload load settings
const { locale } = useI18n();
const settingsStore = useSettingsStore();
const { loadSettings } = settingsStore;

watch(locale, async () => {
  await loadSettings();
});
</script>

<style lang="postcss">
@keyframes rotateAnimation {
  0% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
  25% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  75% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
}

@keyframes showIfLoaded {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

/* .show-if-page-loaded {
  opacity: 0;
  animation: showIfLoaded 1.3s forwards;
  animation-delay: 0.3s;
} */
</style>
