/**
 * With this query, we get all the content from the Sanity -> Settings
 */

export default function getSiteSettingsQuery(locale: string) {
  return groq`{
    "socialMedia":  *[_type=="socialMedia"][0].list,

    "sitemap": *[
      _type=="page" || _type=="room" || _type=="restaurant" || _type=="package" || _type=="activity" &&
      __i18n_lang=="${locale}" &&
      !(_id in path("drafts.**"))
    ] { url, _id, __i18n_lang, live },

    "legal": *[
      _type=="legal" &&
      __i18n_lang=="${locale}"
    ][0] { conditions->{url}, imprint->{url}, privacy->{url}, cookies->{url} },

    "staticText": *[_type=="staticText" && __i18n_lang=="${locale}"] { entry, _id },

    "siteParts": *[_type=="siteParts" && __i18n_lang=="${locale}"][0] {
      ...,
      mediaCorner {
        ...,
        sections[] {
          ...,
          _type == 'downloadList' => {
            ...,
            list[] {
              type == 'file' => { 'file': file.asset->{extension, originalFilename, url}, type, name},
              type == 'image' => { 'picture': picture.refImage->{ 'upload': upload.asset->{url, originalFilename}}, type, name, 'ref': @ },
            }
          },
        }
      }
    },

    "navigations": *[
      _type=="menu" &&
      __i18n_lang=="${locale}"
    ]
  }`;
}
